import { withWrapper } from "app/hooks";
import { useProduct } from "modules/core";
import { ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

interface Props {
  message: string;
  children: ReactNode;
}

export const Error = withWrapper(({ message, children }: Props) => {
  const { product } = useProduct();

  return (
    <div className="my-5 pt-5">
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 font-weight-medium">{children}</h1>
              <h4 className="text-uppercase">{message}</h4>
              <div className="mt-5 text-center">
                <Link className="btn btn-primary" to={`${product || ""}`}>
                  לדף הראשי
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              {/* <img src={errorImage} alt="" className="img-fluid" /> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
});
