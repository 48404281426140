import store from "app/store";
import { ProductProvider } from "modules/core";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import "./index.scss";
import "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ProductProvider>
          {/* <ScrollToTop /> */}
          <App />
        </ProductProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
