import { withWrapper } from "app/hooks";
import { useTitle } from "modules/common";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Home = withWrapper(() => {
  useTitle("");

  return (
    <Container>
      <h1 className="text-center my-4">ראשי</h1>

      <Row>
        <Col md={8} lg={6}>
          <Link to="articles">מאמרים</Link>
        </Col>
      </Row>
    </Container>
  );
});
