import { Product } from "@monorepo/constants";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ThemeProvider } from "react-bootstrap";

const ProductTitle = {
  [Product.Kids]: "ילדים",
  [Product.Teens]: "בני נוער",
  [Product.Vegan]: "צמחונות וטבעונות",
  [Product.Sport]: "ספורט",
  [Product.Maternity]: "היריון והנקה",
  [Product.Adults]: "מבוגרים",
  [Product.Midlife]: "גיל המעבר",
};

interface State {
  product?: Product;
  productTitle?: string;
  setProduct: Dispatch<SetStateAction<Product | undefined>>;
}

const initialState: State = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setProduct: () => {},
};

const ProductContext = createContext(initialState);

export const useProduct = () => useContext(ProductContext);

interface Props {
  children: ReactNode;
}

export const ProductProvider = ({ children }: Props) => {
  const [product, setProduct] = useState<Product | undefined>();

  useEffect(() => {
    const [, pathProduct] = location.pathname.split("/");
    if (Object.values(Product).includes(pathProduct as Product)) {
      setProduct(pathProduct as Product);
    }
  }, []);

  return (
    <ProductContext.Provider
      value={{
        product,
        productTitle: product ? ProductTitle[product] : undefined,
        setProduct,
      }}
    >
      <ThemeProvider dir="rtl">
        <div data-bs-theme={product}>{children}</div>
      </ThemeProvider>
    </ProductContext.Provider>
  );
};
