import {
  faBaby,
  faChild,
  faGrid,
  faHourglassHalf,
  faLeaf,
  faRunning,
  faUser,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Product } from "@monorepo/constants";
import { withWrapper } from "app/hooks";
import classNames from "classnames";
import { useProduct } from "modules/core";
import { useCallback, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";

const buttonClasses = "btn d-flex flex-column justify-content-center gap-2";

export const ProductSwitcher = withWrapper(() => {
  const { product, productTitle, setProduct } = useProduct();

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow((prevValue) => !prevValue);

  const handleProductClick = useCallback((product: Product) => {
    setProduct(product);
    setShow(false);
  }, []);

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      show={show}
      onToggle={toggleShow}
      overlay={
        <Popover>
          <Popover.Body>
            <div
              className="d-grid gap-2"
              style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
            >
              <Link
                to="/kids"
                className={classNames(buttonClasses, "btn-kids", {
                  active: product === Product.Kids,
                })}
                onClick={() => handleProductClick(Product.Kids)}
              >
                <FontAwesomeIcon icon={faChild} />
                ילדים
              </Link>
              <Link
                to="/teens"
                className={classNames(buttonClasses, "btn-teens", {
                  active: product === Product.Teens,
                })}
                onClick={() => handleProductClick(Product.Teens)}
              >
                <FontAwesomeIcon icon={faChild} />
                בני נוער
              </Link>
              <Link
                to="/vegan"
                className={classNames(buttonClasses, "btn-vegan", {
                  active: product === Product.Vegan,
                })}
                onClick={() => handleProductClick(Product.Vegan)}
              >
                <FontAwesomeIcon icon={faLeaf} />
                צמחונות וטבעונות
              </Link>
              <Link
                to="/sport"
                className={classNames(buttonClasses, "btn-sport", {
                  active: product === Product.Sport,
                })}
                onClick={() => handleProductClick(Product.Sport)}
              >
                <FontAwesomeIcon icon={faRunning} />
                ספורט
              </Link>
              <Link
                to="/maternity"
                className={classNames(buttonClasses, "btn-maternity", {
                  active: product === Product.Maternity,
                })}
                onClick={() => handleProductClick(Product.Maternity)}
              >
                <FontAwesomeIcon icon={faBaby} />
                היריון והנקה
              </Link>
              <Link
                to="/adults"
                className={classNames(buttonClasses, "btn-adults", {
                  active: product === Product.Adults,
                })}
                onClick={() => handleProductClick(Product.Adults)}
              >
                <FontAwesomeIcon icon={faUser} />
                מבוגרים
              </Link>
              <Link
                to="/midlife"
                className={classNames(buttonClasses, "btn-midlife", {
                  active: product === Product.Midlife,
                })}
                onClick={() => handleProductClick(Product.Midlife)}
              >
                <FontAwesomeIcon icon={faHourglassHalf} />
                גיל המעבר
              </Link>
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      <Button
        className="d-flex align-items-center gap-2"
        variant="outline-light"
      >
        <FontAwesomeIcon icon={faGrid} />
        {productTitle}
      </Button>
    </OverlayTrigger>
  );
});
