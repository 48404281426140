import { useProduct } from "modules/core";
import { useEffect } from "react";

const titlePrefix = "עדי יאנה | תזונאית קלינית";

export const useTitle = (title: string) => {
  const { productTitle } = useProduct();

  useEffect(() => {
    document.title = `${titlePrefix}`;

    if (productTitle) {
      document.title += ` | ${productTitle}`;
    }

    if (title) {
      document.title += ` | ${title}`;
    }
  }, [productTitle, title]);
};
