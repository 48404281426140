import { withWrapper } from "app/hooks";
import isEmpty from "lodash/isEmpty";
import { productNavLinks, ProductSwitcher, useProduct } from "modules/core";
import { useMemo } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavbarCollapse,
  NavItem,
  NavLink,
} from "react-bootstrap";
import { NavLink as NavLinkRouter } from "react-router-dom";
import { Logo } from "./Logo";

export const Navigator = withWrapper(() => {
  const { product } = useProduct();
  const navLinks = useMemo(() => {
    if (product) {
      return productNavLinks[product];
    }

    return [];
  }, [product]);

  return (
    <Navbar bg="primary" className="navigator mb-3" expand="sm" sticky="top">
      <Container>
        <Logo />
        <ProductSwitcher />
        <Navbar.Toggle aria-controls="navigator-navbar" />
        <NavbarCollapse id="navigator-navbar">
          <Nav variant="underline">
            {navLinks.map((link) => (
              <NavItem key={link.path}>
                <NavLink
                  as={NavLinkRouter}
                  to={`/${product}${isEmpty(link.path) ? "" : `/${link.path}`}`}
                  end
                >
                  {link.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </NavbarCollapse>
      </Container>
    </Navbar>
  );
});
