import { zIndexLevels } from "@monorepo/constants";
import { ReactNode } from "react";
import { Container } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { Navigator } from "../Header";
import { Footer } from "./Footer";

interface Props {
  children: ReactNode;
}

export const SharedLayout = ({ children }: Props) => (
  <>
    <Toaster containerStyle={{ zIndex: zIndexLevels.level1e7 }} />
    <Navigator />
    <Container>{children}</Container>
    <Footer />
  </>
);
