import { useProduct } from "modules/core";
import { NavbarBrand } from "react-bootstrap";
import style from "./Logo.module.scss";

export const Logo = () => {
  const { product } = useProduct();

  return (
    <NavbarBrand
      className="d-flex flex-column align-items-center text-white"
      href={product ? `/${product}` : "/"}
    >
      <span className={style.MainText}>עדי יאנה</span>
      <span className={style.SubText}>ליווי תזונתי</span>
    </NavbarBrand>
  );
};
