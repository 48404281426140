import { Product } from "@monorepo/constants";
import { PublicLayout } from "modules/common";
import { Home, NotFound, productPublicRoutes, ServerError } from "modules/core";

const publicRoutes = [
  { path: "/", element: <Home /> },

  // error pages
  { path: "4xx-error", element: <NotFound /> },
  { path: "5xx-error", element: <ServerError /> },

  // not found routes
  { path: "*", element: <NotFound /> },
];

const get = (product?: Product) => [
  product
    ? {
        path: `/${product}`,
        element: <PublicLayout />,
        children: productPublicRoutes[product],
      }
    : {},
  {
    path: `/`,
    element: <PublicLayout />,
    children: publicRoutes,
  },
];

export { get };
