import { withWrapper } from "app/hooks";
import { useTitle } from "modules/common";
import { Container } from "react-bootstrap";

export const Articles = withWrapper(() => {
  useTitle("מאמרים");

  return (
    <Container>
      <h1 className="text-center my-4">מאמרים</h1>
    </Container>
  );
});
