import { Product } from "@monorepo/constants";
import { Articles } from "modules/articles";
import { Home } from "modules/core";

interface Route {
  path: string;
  element: JSX.Element;
}

interface NavLink {
  path: string;
  label: string;
}

export const productPublicRoutes: Record<Product, Route[]> = {
  [Product.Kids]: [
    { path: "", element: <Home /> },
    { path: "articles", element: <Articles /> },
    { path: "articles", element: <Articles /> },
  ],
  [Product.Teens]: [{ path: "", element: <Home /> }],
  [Product.Vegan]: [{ path: "", element: <Home /> }],
  [Product.Sport]: [{ path: "", element: <Home /> }],
  [Product.Maternity]: [{ path: "", element: <Home /> }],
  [Product.Adults]: [{ path: "", element: <Home /> }],
  [Product.Midlife]: [{ path: "", element: <Home /> }],
};

export const productNavLinks: Record<Product, NavLink[]> = {
  [Product.Kids]: [
    { path: "", label: "בית" },
    { path: "articles", label: "מאמרים" },
  ],
  [Product.Teens]: [{ path: "", label: "בית" }],
  [Product.Vegan]: [{ path: "", label: "בית" }],
  [Product.Sport]: [{ path: "", label: "בית" }],
  [Product.Maternity]: [{ path: "", label: "בית" }],
  [Product.Adults]: [{ path: "", label: "בית" }],
  [Product.Midlife]: [{ path: "", label: "בית" }],
};
